






import { Component, Vue, Watch } from 'vue-property-decorator';

import agreement from './index.vue';
@Component({
    name: 'AgreementType',
    components: {
        agreement
    }
})
export default class AgreementType extends Vue {
    public type: string = '';

    private mounted() {
        const { type } = this.$route.params;
        this.type = type;
    }

    @Watch('$route')
    private change() {
        const { type } = this.$route.params;
        this.type = type;
    }
}
